import React from 'react';
import './CV.css';

const CV = () => {
  return (
    <div className="cv-container">
      <h2>My CV</h2>
      <div className="pdf-container">
        {/* Embed ile PDF dosyasını sayfaya gömme */}
        <embed src="/cv.pdf" type="application/pdf" width="100%" height="800px" />
      </div>
    </div>
  );
};

export default CV;