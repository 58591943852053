import React from 'react';
import './TeachingExperience.css'; // Stil dosyasını ekleyelim

const teachingData = [
  {
    institution: ' ',
    position: ' ',
    course: ' ',
    term: ' ',
  },
  
];

const TeachingExperience = () => {
  return (
    <div className="teaching-container">
      <h2>Teaching Experience</h2>
      <ul className="teaching-list">
        {teachingData.map((experience, index) => (
          <li key={index} className="teaching-item">
            <h3>{experience.institution}</h3>
            <p><strong>Position:</strong> {experience.position}</p>
            <p><strong>Course:</strong> {experience.course}</p>
            <p><strong>Term:</strong> {experience.term}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TeachingExperience;