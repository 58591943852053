import React from "react";
import "./Research.css"; // Stil dosyasını import edelim

const researchData = [
  {
    title:
      "How can Degrowth and Democratic Planning Policies be Implemented? An Empirical and Theoretical Investigation",
    date: "Abstract",
    description:
      ' This article empirically and practically examines the implementation of degrowth and democratic planning policies to address escalating ecological crises and socioeconomic inequalities. Despite the 2015 Paris Agreement’s targets, global greenhouse gas emissions continue to rise, highlighting the need for more radical action. Degrowth, as a planned reduction in resource use, seeksto balance the economy with ecological sustainability, improve well-being, and reduce inequality. This study explores the structural transformations needed to support degrowth, especially its viabilitybeyond capitalism. Using the Environmentally Extended Multi-Regional Input-Output (EE-MRIO) dataset, this study models a degrowth policy mix’s impact in six countries—USA, Germany, UK, China,Turkey, and South Africa. The four key policies include reducing output in ecologically destructive sectors, reducing work hours, redistributing income, and implementing universal basic income. These policies, set within a democratic planning framework, address both demand and supply sides to reduce emissions and income inequality. Collectively, the six countries contributed 24,044,285 kilotons of carbon-equivalent emissions in 2021. Under the proposed degrowth policies and "business-as-usual" technological advances, emissions are projected to decrease by 56%, aligning with IPCC recommendations for a 60% reduction by 2035. The results indicate that these policies could significantly mitigate emissions but would require complementary measures, such as work-hour reductions, to manage employment impacts. This article argues that such policies are incompatible with capitalism’s growth imperative and instead proposes a democratic planning framework to prioritize social and ecological well-being over profit. This study serves as an empirical foundation for assessing degrowth’s potential to transition toward sustainable, equitable economies.',
  },
];

const Research = () => {
  return (
    <div className="research-container">
      <h2>Job Market Paper
      </h2>
      <ul className="research-list">
        {researchData.map((research, index) => (
          <li key={index} className="research-item">
            <h3>{research.title}</h3>
            <p>
              <strong>Date:</strong> {research.date}
            </p>
            <p>{research.description}</p>
        
          </li>
        ))}
      </ul>
      <div className="pdf-container">
        {/* Embed ile PDF dosyasını sayfaya gömme */}
        <embed
          src="/JB.pdf"
          type="application/pdf"
          width="100%"
          height="800px"
        />
      </div>
     
    </div>
  );
};

export default Research;
