import React from 'react';
import './Publications.css'; // Stil dosyasını import edelim

const publicationsData = [
  {
    title: 'THE EFFECT OF OIL CRISES ON ECONOMIES OF COMECON/CMEA MEMBER COUNTRIES, A FIVE-COUNTRY STUDY: CSSR, GDR, HUNGARY, POLAND, AND USSR',
    journal: '',
    year: '2018',
    link: 'https://digitalcommons.bard.edu/levy_ms/11/',
  },
  
];

const Publications = () => {
  return (
    <div className="publications-container">
      <h2>Publications</h2>
      <ul className="publications-list">
        {publicationsData.map((pub, index) => (
          <li key={index} className="publications-item">
            <h3>{pub.title}</h3>
            <p><strong>Journal/Conference:</strong> {pub.journal}</p>
            <p><strong>Year:</strong> {pub.year}</p>
            {pub.link && <a href={pub.link} target="_blank" rel="noopener noreferrer">Read more</a>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Publications;