import React from 'react';
import './Footer.css'; // CSS dosyasını import edelim
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
  return (
    <footer>
      <div className="social-links">
      <a
          href="https://www.linkedin.com/in/co%C5%9Fku-m%C4%B1hc%C4%B1-072532110/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
      
      </div>

      {/* Created by Markaevi Bölümü */}
      <div className="created-by">
        <a href="https://www.markaevi.com.tr" target="_blank" rel="noopener noreferrer">

        </a>
        <p>Created by <a href="https://www.markaevi.com.tr" target="_blank" rel="noopener noreferrer">Markaevi</a></p>
      </div>
    </footer>
  );
};

export default Footer;