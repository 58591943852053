import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  return (
    <header>
      <div className="header-left">
       <Link to="/ "><h1>Coşku Mıhçı</h1></Link> 
      </div>
      <nav className="header-menu">
        <ul>
          <li>
            <Link to="/cv">CV</Link>
          </li>
          <li>
            <Link to="/job-market-paper">Research</Link>
          </li>
          <li>
            <Link to="/teaching">Teaching Experience</Link>
          </li>
          <li>
            <Link to="/publications">Publications</Link>
          </li>
        </ul>
      </nav>
      <div className="header-right">
        <a
          href="https://www.linkedin.com/in/co%C5%9Fku-m%C4%B1hc%C4%B1-072532110/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>

      </div>
    </header>
  );
};

export default Header;
