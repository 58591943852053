import React from "react";
import "./Homepage.css";
import { Link } from "react-router-dom";
import profilePic from "../images/pp.jpg"; // CV fotoğrafı

const Homepage = () => {
  return (
    <div className="homepage-container">
      <section className="intro-section">
        <div className="intro-photo">
          <img src={profilePic} alt="Coşku Mıhçı's CV" />
        </div>
        <div className="intro-text">
          <h1>Hello and welcome!</h1>
          <h2>Coşku Mıhçı</h2>
          <p>
            My name is Cosku Mihci and I am a PhD candidate in Economics at the
            University of Massachusetts Amherst.
          </p>
        </div>
      </section>
      
      {/* Research Explanation Section */}
      <section className="research-section">
        <h2>Brief Explanation of My Research</h2>
        <p>
        My doctoral work focuses on the political economy of climate change and
      comparative economic systems. My research and teaching interests include
      political economy, macroeconomics, environmental economics, history of
      economic thought, and international economics. 
          <br /> I hold an M.A. in Economics
      from the University of Massachusetts Amherst, M.S. in Economic Theory and
      Policy from Levy Economics Institute of Bard College, and a B.S. in
      Economics from the Middle East Technical University.
        </p>
      </section>
      <section className="links-section">
        <h2>Quick Links</h2>
        <ul>
          <li>
            <Link to="/cv">View CV</Link>
          </li>
          <li>
            <Link to="/job-market-paper">Research</Link>
          </li>
          <li>
            <Link to="/publications">Publications</Link>
          </li>
          <li>
            <Link to="/teaching">Teaching Experience</Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Homepage;
