// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Homepage from "./components/Homepage";
import "./App.css"; // Genel stil dosyanız
import CV from "./components/CV"
import Research from "./components/Research"
import TeachingExperience from "./components/TeachingExperience"
import Publications from "./components/Publications"

const App = () => {
  return (
  <div>
    <Router>
      <Header/>
      <Routes>
      <Route path="/" element={<Homepage/>}/>
      <Route path="/cv" element={<CV/>}/>
      <Route path="/job-market-paper" element={<Research/>}/>
      <Route path="/teaching" element={<TeachingExperience/>}/>
      <Route path="/publications" element={<Publications/>}/>


      </Routes>
      <Footer/>
    </Router>
  </div>
  );
};

export default App;